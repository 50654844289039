<route>
  {
    "meta": {
      "permission": ["equipements.add_equipement", "equipements.change_equipement"]
    }
  }
</route>

<template>
  <v-container fluid>
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <i-toolbar
            :title="
              equipment.pk
                ? `${$t('edit')} ${$tc('equipment', 2)}`
                : `${$t('add', { value: $tc('equipment', 2) })}`
            "
            :loading="load"
          ></i-toolbar>
          <v-card-text>
            <v-row row wrap>
              <v-col cols="4">
                <!-- Imagen -->
                <v-skeleton-loader
                  v-if="loadingIMG"
                  type="image"
                  tile
                ></v-skeleton-loader>
                <i-image
                  v-else
                  v-model="imageUrl"
                  :file.sync="equipment.image"
                />

                <!-- Departamentos de producción -->
                <v-col v-if="departmentBtn.view" cols="12" class="py-10">
                  <dialdepartment
                    v-model="dialog2"
                    :to-edit="toEdit"
                    @done="getDepartments"
                  />
                  <ValidationProvider
                    vid="department"
                    :name="$t('department')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      autocomplete="off"
                      outlined
                      v-model="equipment.production_departament_id"
                      :clearable="departments.length > 10"
                      :error-messages="errors[0]"
                      :items="departments"
                      :loading="isLoading4"
                      class="secondary--text"
                      item-text="name"
                      item-value="pk"
                      :append-icon="departments.length > 10 ? 'fa-search' : ''"
                      :prepend-inner-icon="departmentBtn.add ? 'fa-plus' : ''"
                      @click:prepend-inner="openDepartment()"
                      :label="$t('department')"
                    ></v-autocomplete>
                  </ValidationProvider>
                </v-col>
              </v-col>

              <!-- Nombre del equipo -->
              <v-col cols="4" class="py-3">
                <v-col>
                  <ValidationProvider
                    vid="name"
                    :name="$t('name')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      autocomplete="off"
                      outlined
                      :error-messages="errors[0]"
                      v-model="equipment.name"
                      color="secondary"
                      :label="$tc('name', 1)"
                    />
                  </ValidationProvider>
                </v-col>

                <!-- Serial del equipo -->
                <v-col>
                  <ValidationProvider
                    vid="serial"
                    :name="$t('serial')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      autocomplete="off"
                      outlined
                      :error-messages="errors[0]"
                      v-model="equipment.serial"
                      color="secondary"
                      :label="$tc('serial', 1)"
                    />
                  </ValidationProvider>
                </v-col>

                <!-- Tipo de adquisición del equipo -->
                <v-col class="py-5">
                  <h6>{{ $tc('type_acquisition') }}</h6>
                  <div class="container-v-switch pt-2">
                    <label :class="!equipment.rent ? 'active' : ''" for="buy">{{
                      $t('buy')
                    }}</label>
                    <v-switch
                      id="mode"
                      class="mt-0 pt-0"
                      color="primary"
                      v-model="equipment.rent"
                    ></v-switch>
                    <label :class="equipment.rent ? 'active' : ''" for="rent">{{
                      $tc('rent')
                    }}</label>
                  </div>
                </v-col>

                <v-col>
                  <!-- Compra -->
                  <v-col v-if="!equipment.rent">
                    <i-date-picker
                      :loading="load"
                      v-model="equipment.bought_date"
                      :label="$tc('bought_date', 1)"
                      color="primary"
                    />
                  </v-col>

                  <!-- Fecha inicial de renta -->
                  <v-col v-if="equipment.rent" cols="12" class="py-3">
                    <i-date-picker
                      :loading="loading"
                      v-model="equipment.rent_start_date"
                      :label="$tc('rent_start_date', 1)"
                      color="primary"
                    />
                  </v-col>

                  <!-- Fecha final de renta -->
                  <v-col v-if="equipment.rent" cols="12" class="py-0">
                    <i-date-picker
                      :loading="loading"
                      v-model="equipment.rent_end_date"
                      :label="$tc('rent_end_date', 1)"
                      color="primary"
                    />
                  </v-col>
                </v-col>
              </v-col>

              <!-- Marca y modelo -->
              <v-col cols="4">
                <v-col v-if="brandBtn.view" cols="12" sm="11">
                  <i-brand
                    autocomplete="off"
                    outlined
                    v-model="equipment.model"
                    color="secondary"
                    :label="$tc('model', 1)"
                  />
                </v-col>

                <!-- Mantenimiento de equipo -->
                <v-col class="py-8">
                  <h6>{{ $tc('equipment_maintenance') }}</h6>
                  <div class="container-v-switch pt-2">
                    <label
                      :class="equipment.recurrent ? '' : 'active'"
                      for="recurrent_maintenance"
                    >
                      {{ $t('regular') }}
                    </label>
                    <v-switch
                      id="recurrent_maintenance"
                      class="mt-0 pt-0"
                      color="primary"
                      v-model="equipment.recurrent"
                    ></v-switch>
                    <label
                      :class="equipment.recurrent ? 'active' : ''"
                      for="recurrent_maintenance"
                    >
                      {{ $tc('recurrent', 2) }}
                    </label>
                  </div>
                </v-col>

                <!-- Tipo de recurrencia del mantenimiento de equipo -->
                <v-col v-if="equipment.recurrent" class="py-3">
                  <ValidationProvider
                    vid="type_recurrent"
                    :name="$t('type_recurrent')"
                    :rules="{ required: equipment.recurrent }"
                    v-slot="{ errors }"
                  >
                    <v-select
                      autocomplete="off"
                      outlined
                      :error-messages="errors[0]"
                      v-model="equipment.type_recurrent"
                      item-text="state"
                      item-value="abbr"
                      :items="[
                        { state: $t('yearly'), abbr: 'year' },
                        { state: $t('monthly'), abbr: 'month' },
                        { state: $t('weekly'), abbr: 'week' }
                      ]"
                      :label="$t('type_recurrent')"
                    ></v-select>
                  </ValidationProvider>
                </v-col>

                <!-- Días de mantenimiento de equipo -->
                <v-col
                  v-if="
                    equipment.recurrent && equipment.type_recurrent === 'week'
                  "
                  class="py-0"
                >
                  <ValidationProvider
                    vid="days"
                    :name="$t('days')"
                    :rules="{ required: equipment.type_recurrent === 'week' }"
                    v-slot="{ errors }"
                  >
                    <v-select
                      autocomplete="off"
                      outlined
                      :error-messages="errors[0]"
                      v-model="equipment.day"
                      :items="days"
                      :label="$t('days')"
                      item-text="value"
                      item-value="key"
                    ></v-select>
                  </ValidationProvider>
                </v-col>
              </v-col>
            </v-row>

            <!-- Descripción del equipo -->
            <v-row>
              <v-col cols="12">
                <ValidationProvider
                  vid="description"
                  :name="$tc('description', 1)"
                  rules=""
                  v-slot="{ errors }"
                >
                  <label for="description">{{ $t('description') }}</label>
                  <vue-editor
                    id="body"
                    v-model="equipment.description"
                    :error-messages="errors[0]"
                    :editorToolbar="customToolbar"
                  />
                  <span class="areaErrors" v-if="errors[0]">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
        </form>
      </ValidationObserver>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { VueEditor } from 'vue2-editor'
import dialdepartment from '../../components/department/modal.vue'

export default {
  components: {
    dialdepartment,
    VueEditor
  },
  data() {
    return {
      load: false,
      equipment: {
        pk: '',
        name: '',
        model: null,
        serial: '',
        description: '',
        bought_date: null,
        rent_start_date: null,
        rent_end_date: null,
        image: null,
        production_departament_id: null,
        mode: 'buy',
        recurrent: false,
        rent: false,
        day: '',
        type_recurrent: ''
      },
      loading: false,
      isLoading4: false,
      dialog2: false,
      toEdit: null,
      departments: [],
      loadingIMG: false,
      imageUrl: null,
      days: [
        { key: 'mon', value: this.$t('days_list.mon') },
        { key: 'tue', value: this.$t('days_list.tue') },
        { key: 'wed', value: this.$t('days_list.wed') },
        { key: 'thu', value: this.$t('days_list.thu') },
        { key: 'fri', value: this.$t('days_list.fri') },
        { key: 'sat', value: this.$t('days_list.sat') },
        { key: 'sun', value: this.$t('days_list.sun') }
      ],
      customToolbar: [
        [{ header: [] }],
        [
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'code',
          'code-block'
        ],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ color: [] }, { background: [] }]
      ]
    }
  },
  watch: {
    'equipment.rent': {
      handler(val) {
        if (val) this.equipment.mode = val ? 'rent' : 'buy'
      }
    },
    'equipment.mode': {
      handler(val) {
        this.equipment.rent = val === 'rent'
      }
    }
  },
  computed: {
    ...mapGetters({
      company: 'company/getCompanyData',
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    /**
     * departmentBtn
     * Computado que determina si el usuario tiene permisos para agregar o ver
     * departamentos de producción.
     * Retorna un objeto con propiedades para agregar y ver.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    departmentBtn() {
      return {
        add:
          this.getPermissions([`base_config.add_productiondepartment`]) ||
          this.isAdmin,
        view:
          this.getPermissions([`base_config.view_productiondepartment`]) ||
          this.isAdmin
      }
    },
    /**
     * brandBtn
     * Computado que determina si el usuario tiene permisos para ver marcas y
     * modelos.
     * Retorna un objeto con una propiedad para ver.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    brandBtn() {
      return {
        view:
          this.getPermissions([`equipements.view_brandmodel`]) || this.isAdmin
      }
    }
  },
  methods: {
    /**
     * openDepartment
     * Método para abrir el diálogo de selección de departamento de producción.
     * Inicializa el objeto a editar si se proporciona uno.
     *
     * @param {Object|null} item - El departamento a editar o null.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    openDepartment(item) {
      this.toEdit = item || null
      this.dialog2 = true
    },
    /**
     * submit
     * Método para enviar el formulario de equipo, ya sea creando un nuevo
     * equipo o editando uno existente.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async submit() {
      this.load = true
      const form_data = new FormData()
      Object.entries(this.equipment).forEach(([key, value]) => {
        if (value != null) {
          form_data.append(key, value)
          if (key === 'image' && this.isImageInvalid(value)) {
            form_data.delete('image')
          }
        }
      })
      try {
        await (this.equipment.pk
          ? this.$api.equipment.edit({ pk: this.equipment.pk, form: form_data })
          : this.$api.equipment.create({ form: form_data }))
        this.$toast.success(
          `${this.$tc('equipment', 1)} ${this.$tc(
            this.equipment.pk ? 'edited' : 'created',
            2
          )}`
        )
        this.$router.push({ name: 'equipment' })
      } catch (error) {
        console.error('Error submitting form:', error)
        this.$toast.error(this.$t('required'))
      } finally {
        this.load = false
      }
    },
    /**
     * isImageInvalid
     * Método que valida si la imagen proporcionada es válida.
     * Retorna true si la imagen es inválida, de lo contrario false.
     *
     * @param {any} image - La imagen a validar.
     * @returns {boolean}
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    isImageInvalid(image) {
      return (
        !image ||
        String(image).includes('http') ||
        String(image).includes(';base64') ||
        String(image).includes('/sources/')
      )
    },
    /**
     * getDepartments
     * Método asíncrono para obtener la lista de departamentos de producción.
     * Actualiza el estado de departamentos en el componente.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getDepartments() {
      const { data } = await this.$api.department.list({
        opt: { params: { company_id: this.company.pk } }
      })
      this.departments = data.results
    },
    /**
     * getEquipment
     * Método asíncrono para obtener los detalles de un equipo específico.
     * Si el equipo es nuevo, reinicia el formulario; de lo contrario, carga
     * los datos del equipo.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getEquipment() {
      const validation = this.codification({
        mode: 'decode',
        code: this.$route.params.pk
      })
      if (validation === 'create') {
        this.equipment = {
          pk: '',
          name: '',
          model: null,
          serial: '',
          description: '',
          bought_date: null,
          rent_start_date: null,
          rent_end_date: null,
          image: null,
          production_departament_id: null,
          mode: 'buy',
          recurrent: false,
          rent: false,
          day: '',
          type_recurrent: ''
        }
        this.imageUrl = null
      } else {
        try {
          const { data } = await this.$api.equipment.show({ pk: validation })
          this.equipment = data
          this.imageUrl = data.image
          this.$route.meta.title = this.$t('edit', { model: '' })
        } catch {
          this.$router.push({ name: 'equipment' })
        }
      }
    }
  },
  mounted() {
    this.getDepartments()
    this.getEquipment()
  }
}
</script>
